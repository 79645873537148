import React, { Fragment, useEffect } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import Spinner from '../common/Spinner';

interface INewUiModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function NewUiModal({ open, setOpen }: INewUiModalProps) {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
        // Open the new UI at: https://preview.snowflake.dataops.live/
        window.open('https://preview.snowflake.dataops.live/', '_blank');
      }, 4000);

      // Cleanup function to clear the timeout if the component unmounts or open changes
      return () => {
        clearTimeout(timer);
      };
    }
  }, [open, setOpen]);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30 " onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg p-6 space-y-4 bg-gray-50 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <div className="flex items-center gap-2 text-xl">
                  <span>Launching the new UI in a few seconds...</span>
                  <Spinner />
                </div>

                <div>The new UI will become the default early 2025.</div>
                <div>Give us your feedback on the Slack channels!</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
