import React, { useState } from 'react';
import Button from './Button';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import NewUiModal from '../modals/NewUiModal';
import { sendMetrics } from '../../utilities/analytics';

export default function NewUiButton() {
  const [open, setOpen] = useState(false);
  function clickHandler() {
    const analyticsData = {
      event_name: 'dataops-sc-ui-navigation-try-new-ui',
      event_source: 'frostbyte-deployment-portal',
    };
    sendMetrics(analyticsData);
    setOpen(true);
  }
  return (
    <div>
      <NewUiModal open={open} setOpen={setOpen} />
      <Button
        onClick={() => {
          clickHandler();
        }}
        size={'large'}
        className="hidden lg:flex space-x-1 hover:bg-dataops-light-blue hover:ring-2 ring-dataops-light-blue ring-inset hover:outline hover:outline-2 outline-dataops-light-blue/50"
        data-testid="nav-preview-ui-button"
      >
        <RocketLaunchIcon className="hidden xl:block w-5 h-5 mr-2" />
        <span>Try the new UI</span>
      </Button>
    </div>
  );
}
